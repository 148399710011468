import React, { Component } from "react";
import "../../sass/app.scss";

export default class LogoBackground extends Component {
  render() {
    return (
      <div>
        <svg
          id='bg'
          xmlns='http://www.w3.org/2000/svg'
          x={0}
          y={0}
          viewBox='0 0 501.4 340.1'
          style={{
            enableBackground: "new 0 0 501.4 340.1",
          }}
          xmlSpace='preserve'
        >
          <g id='logo' transform='translate(-834.574 -5137.63)'>
            <path
              id='Path_86'
              className='logo_bg'
              d='M1150.5 5305.6c0 21.2-5.9 42.8-17.8 64.7-11.7 21.7-28.4 40.4-48.8 54.4-20.7 14.4-43.5 21.6-68.5 21.6-38.7 0-72.2-16-100.4-47.9-28.1-31.9-42.2-72.8-42.2-122.9 0-26.5 3.7-53.9 11-82.3H1094c9.5 15.8 21.4 19.7 39.6 48.6 12.8 20.4 16.9 42.5 16.9 63.8'
            />
            <path
              id='Path_87'
              className='logo_bg'
              d='M1177.6 5146.8v321.7h-69.5v-46.3c-24.5 34.1-62.4 54.7-112.6 54.7-87.5 0-160.2-74-160.2-169.2 0-95.9 72.7-169.2 160.2-169.2 50.2 0 88.2 20.6 112.6 54v-45.7h69.5zm-69.5 160.9c0-59.8-43.8-103-101.7-103s-101.7 43.1-101.7 103c0 59.8 43.8 102.9 101.7 102.9s101.7-43.1 101.7-102.9'
            />
            <path
              id='Path_88'
              className='logo_bg'
              d='M1319.5 5218.9c-8.6 8.8-19.1 13.2-31.5 13.2-12.4 0-22.8-4.4-31.2-13.2-8.4-8.8-12.6-19.4-12.6-31.8-.2-11.6 4.4-22.7 12.6-30.9 8.4-8.6 18.8-12.9 31.2-12.9 12.4 0 22.9 4.3 31.5 12.9 8.6 8.6 12.9 18.9 12.9 30.9 0 12.4-4.3 23-12.9 31.8'
            />
            <path
              id='Path_89'
              className='logo_bg'
              d='M1321.4 5300.1c9.2 10.5 13.8 23.9 13.8 40.3 0 14.8-3.5 30.4-10.5 47-7.2 16.9-16.9 32.6-28.9 46.4-11.9 14-25.8 26.2-41.2 36.3l-18.4-38.7c10.7-9 38.4-41.7 27.1-63-3.2-6.1-7.2-6.9-13.5-16.9-4.2-6.7-5.5-13.9-5.5-20.9s1.9-14 5.8-21.2c3.9-7.1 9.3-13.2 16-17.8 6.6-4.6 14.4-7.1 22.4-7.1 12.7-.1 23.7 5.1 32.9 15.6'
            />
          </g>
        </svg>
      </div>
    );
  }
}
